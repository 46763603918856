import NoEventIconSvgPath from "./assets/no-event-icon.svg";
import NoFileIconSvgPath from "./assets/no-file-icon.svg";
import NoGraphIconSvgPath from "./assets/no-graph-icon.svg";
import NoKarteIconSvgPath from "./assets/no-karte-icon.svg";

export {
    NoEventIconSvgPath,
    NoFileIconSvgPath,
    NoGraphIconSvgPath,
    NoKarteIconSvgPath,
};
